import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "text" ]

  static values = {
    eventStartDate: String,
    nowText: String,
    transitionedBeforeLoad: { type: Boolean, default: false }
  }

  initialize() {
    this.transitionCompleted = false
  }

  eventStartDateValueChanged() {
    this.eventStartsAt = new Date(this.eventStartDateValue)
  }

  connect() {
    this.transitionCompleted = this.transitionedBeforeLoadValue

    if (!this.transitionCompleted) {
      this.activateRefresher()
      this.refresh()
    }
  }

  disconnect() {
    this.deactivateRefresher()
  }

  activateRefresher() {
    const refreshFrequencyInMs = 1000

    this.refresher = setInterval(() => {
      this.refresh()
    }, refreshFrequencyInMs)
  }

  deactivateRefresher() {
    if (this.refresher) {
      clearInterval(this.refresher)
    }
  }

  refresh() {
    const passedStartDate = this.checkStartDatePassed()

    if (passedStartDate && !this.transitionCompleted) {
      this.transition()
      this.deactivateRefresher()
    }
  }

  checkStartDatePassed() {
    const now = new Date()
    return (now >= this.eventStartsAt)
  }

  transition() {
    this.textTarget.textContent = this.nowTextValue
    this.transitionCompleted = true
  }
}